<template>
  <section>
    <div class="mb-1">
      <strong class="text-dark">
        <a href="#" @click.prevent="goBack"> Kembali </a>
      </strong>
    </div>
    <b-card>
      <h2 class="mb-2"><strong>Rasionalisasi Bintara</strong></h2>
      <p>
        Berdasarkan rumus yang sudah disepakati di file excel, sistem
        rasionalisasi bintara ditentukan oleh sub kategori paket bintara dan
        passing grade nya. Secara ideal rumus rasionalisasi bintara, paket
        bintara harus terdiri dari 2 sub kategori:
      </p>
      <ol class="mb-1">
        <li><strong>Tes Psikotes</strong></li>
        <li><strong>Tes Akademik</strong></li>
      </ol>
      <p>
        Dari 2 sub kategori tersebut harus ditentukan passing grade
        masing-masing sub kategori, karena sistem ranking hasil tryout bintara
        akan memunculkan 2 versi, ranking versi
        <b>Tes Psikotes</b> dan versi <b>Tes Akademik</b>. Silahkan lihat
        gambaran rasionalisasi bintara dibawah ini:
      </p>
      <section class="mb-1">
        <img
          :src="flowImageUrl"
          class="w-100 cursor-pointer"
          @click="viewDetail"
          alt="Flow Bintara"
        />
      </section>
      <p>
        Dari gambar tersebut terlihat didalam setiap sub kategori, terdapat
        ujian-ujian yang sudah ditentukan di tab <strong>Ujian</strong> di
        detail <strong>Paket Tryout</strong>. Di setiap ujian dalam 1 sub
        kategori juga harus ditentukan bobot persentase nya untuk mendapatkan
        hasil nilai sesuai dengan rumus rasionalisasi Bintara.
      </p>
      <p>Langkah yang harus dilakukan ialah:</p>
      <ol>
        <li style="margin-bottom: 10px">
          <strong>Admin</strong> membuka menu
          <strong>Paket Tryout > Bintara > Detail</strong>
        </li>
        <li style="margin-bottom: 10px">
          Pastikan pada tab <strong>Detail</strong>, bagian opsi
          <strong>Tipe Rasionalisasi</strong> terpilih opsi
          <strong>Bintara</strong>
        </li>
        <li style="margin-bottom: 10px">
          Pastikan pada tab <strong>Ujian</strong>, terdapat 2 Sub Kategori
          dengan nama <strong>Tes Psikotes</strong> dan
          <strong>Tes Akademik</strong>.
        </li>
        <li style="margin-bottom: 10px">
          Pada tab <strong>Ujian</strong>, silahkan masukan ujian-ujian kedalam
          masing-masing sub kategori.
        </li>
        <li style="margin-bottom: 10px">
          Setelah itu, klik tab <strong>Rasionalisasi</strong>, dan tentukan
          passing grade untuk masing-masing sub kategori. Tentukan juga bobot
          persentase untuk masing-masing ujian di setiap Sub Kategori.
        </li>
      </ol>
    </b-card>
  </section>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
    VueViewer,
  },
  data: () => ({
    flowImageUrl: `${process.env.VUE_APP_API_FILE}/upload/assets/bintara.png`,
  }),
  methods: {
    goBack() {
      if (this.$route.query?.p) {
        const paketId = this.$route.query?.p;
        this.$router.push(
          `/master-admin/paket-tryout/detail/${paketId}?active_tab=rasionalisasi`
        );
      } else {
        this.$router.go(-1);
      }
    },
    viewDetail() {
      this.$viewerApi({
        images: [this.flowImageUrl],
      });
    },
  },
};
</script>
