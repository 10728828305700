<template>
  <section>
    <div class="mb-1">
      <strong class="text-dark">
        <a href="#" @click.prevent="goBack"> Kembali </a>
      </strong>
    </div>
    <b-card>
      <h2 class="mb-2"><strong>Rasionalisasi Kedinasan</strong></h2>
      <p>
        Berdasarkan rumus excel yang sudah ditetapkan, rasionalisasi Kedinasan
        ditentukan oleh sub kategori dalam paket kedinasan, yang mana harus
        memiliki <strong>Sub Kategori</strong>:
      </p>
      <ol class="mb-1">
        <li><strong>Tes Seleksi Kompetensi Dasar (Tes SKD)</strong></li>
        <li><strong>Tes Psikotes</strong></li>
      </ol>
      <p>
        Yang mana dari setiap sub kategori diatas harus memiliki ujian-ujian
        yang sudah dimasukan kedalamnya. Silahkan lihat bagan dibawah ini
      </p>
      <section class="mb-1">
        <img
          :src="flowImageUrl"
          class="w-100 cursor-pointer"
          @click="viewDetail"
          alt="Flow Bintara"
        />
      </section>
      <p>
        Dari gambar diatas dapat kita peroleh informasi bahwa ujian-ujian yang
        dimasukan kedalam <strong>Sub Kategori SKD</strong>, harus ditentukan
        <strong><i>passing grade bobotnya</i></strong> nya disetiap ujian di
        saat menentuan jadwalnya. Sedangkan ujian-ujian yang dimasukan kedalam
        <strong>Sub Kategori Psikotes</strong>, harus ditentukan bobot
        persentase nya di tab <strong>Rasionalisasi</strong> di detail Paket.
        Pengaturan seperti ini dibuat agar setiap hasil pengerjaan Tryout
        Kedinasan, memiliki hasil berdasarkan
        <strong>Sub Kategori SKD</strong> dan
        <strong>Sub Kategori Psikotes</strong>.
      </p>
      <p>Langkah yang harus dilakukan ialah:</p>
      <ol>
        <li style="margin-bottom: 10px">
          Pastikan di detail Paket Kedinasan, pada tab
          <strong>Detail</strong> dan bagian
          <strong>Tipe Rasionalisasi</strong> nya terpilih
          <strong>Kedinasan.</strong>
        </li>
        <li style="margin-bottom: 10px">
          Pastikan di detail Paket Kedinasan, pada tab
          <strong>Ujian / Subtest</strong> terdapat Sub Kategori
          <strong>Seleksi Kompetensi Dasar</strong> dan
          <strong>Psikotes</strong>.
        </li>
        <li style="margin-bottom: 10px">
          Kemudian pastikan dalam setiap pembuatan
          <strong>Jadwal Paket Kedinasan</strong>, bagian ujian-ujian yang
          termasuk kedalam
          <strong>Sub Kategori Seleksi Kompetensi Dasar</strong> sudah
          ditentukan <strong><i>passing grade</i></strong> nya.Dan untuk
          ujian-ujian di <strong>Sub Kategori SKD</strong>, pastikan harus
          ditentukan <i>passing grade bobot nya</i> di tab Rasionalisasi.
        </li>
        <li style="margin-bottom: 10px">
          Pastikan <strong>Sub Kategori Psikotest</strong> ditandai opsi
          “Gunakan Passing Grade dari pembobotan rasinalisasi” sebagai tanda
          bahwa semua hasil ujian dengan kategori <strong>Psikotest</strong>,
          akan diproses dengan rumus dari bobot rasionalisasi kedinasan.
        </li>
        <li style="margin-bottom: 10px">
          Lalu pastikan untuk ujian-ujian yang termasuk kedalam
          <strong>Sub Kategori Psikotes</strong>, ditentukan
          <strong>bobot persentase</strong> nya di hhalaman detail paket, di tab
          <strong>Rasionalisasi</strong>.
        </li>
      </ol>
    </b-card>
  </section>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
    VueViewer,
  },
  data: () => ({
    flowImageUrl: `${process.env.VUE_APP_API_FILE}/upload/assets/kedinasan.png`,
  }),
  methods: {
    goBack() {
      if (this.$route.query?.p) {
        const paketId = this.$route.query?.p;
        this.$router.push(
          `/master-admin/paket-tryout/detail/${paketId}?active_tab=rasionalisasi`
        );
      } else {
        this.$router.go(-1);
      }
    },
    viewDetail() {
      this.$viewerApi({
        images: [this.flowImageUrl],
      });
    },
  },
};
</script>
