<template>
  <section>
    <div class="mb-1">
      <strong class="text-dark">
        <a href="#" @click.prevent="goBack"> Kembali </a>
      </strong>
    </div>
    <b-card>
      <h2 class="mb-2"><strong>Rasionalisasi UTBK SNBT</strong></h2>
      <p>
        Rasionalisasi UTBK SNBT berbeda dari rasionalisasi lainnya, bisa dilihat
        dari gambar dibawah ini:
      </p>
      <section class="mb-1">
        <img
          :src="flowImageUrl"
          class="w-100 cursor-pointer"
          @click="viewDetail"
          alt="Flow UTBK SNBT"
        />
      </section>
      <p>
        Dari gambar diatas dapat dilihat kita harus memastikan
        <strong><i>passing grade, daya tampung, dan jumlah peminat</i></strong>
        di setiap jurusan harus ditentukan terlebih dahulu. Lalu Admin harus
        mengatur <strong><i>bobot persentase</i></strong> di setiap
        <strong>Sub Kategori</strong> di paket tersebut.
      </p>
      <p>
        Lalu terdapat settingan yg agak banyak, yaitu mengatur
        <strong><i>bobot nilai ujian-ujian di setiap jurusan</i></strong
        >.
      </p>
      <p>
        Dan jangan lupa bagian <strong>Tipe Rasionalisasi</strong> di paket
        tersebut terpilih opsi UTBK. Maka jika semua langkah tersebut sudah
        dilakukan, admin bisa membuat jadwal untuk paket tersebut.
      </p>
    </b-card>
  </section>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
    VueViewer,
  },
  data: () => ({
    flowImageUrl: `${process.env.VUE_APP_API_FILE}/upload/assets/utbk.png`,
  }),
  methods: {
    goBack() {
      if (this.$route.query?.p) {
        const paketId = this.$route.query?.p;
        this.$router.push(
          `/master-admin/paket-tryout/detail/${paketId}?active_tab=rasionalisasi`
        );
      } else {
        this.$router.go(-1);
      }
    },
    viewDetail() {
      this.$viewerApi({
        images: [this.flowImageUrl],
      });
    },
  },
};
</script>
