<template>
  <section>
    <div class="mb-1">
      <strong class="text-dark">
        <a href="#" @click.prevent="goBack"> Kembali </a>
      </strong>
    </div>
    <b-card>
      <h2 class="mb-2"><strong>Rasionalisasi SMA</strong></h2>
      <p>
        Rasionalisasi SMA secara sederhana hanya perlu menetapkan nilai
        penghitung untuk setiap hasil ujian siswa, yang nantinya akan
        menghasilkan nilai akhir sebuah ujian siswa. Secara rumus,
        <strong
          >nilai penghitung ini akan dikali dengan hasil ujian siswa</strong
        >. Secara default,
        <strong
          >angka nilai penghitung / penentu hasil akhir ujian ialah
          <span class="text-danger">2.5</span></strong
        >
      </p>
      <p>
        Sebagai contoh terdapat nilai seorang siswa yang ditunjukan oleh tabel
        berikut:
      </p>
      <table class="table table-bordered table-striped">
        <tr>
          <th class="text-center" style="width: 3%">No</th>
          <th class="text-center">Mata Pelajaran</th>
          <th class="text-center">Jumlah Benar</th>
          <th class="text-center">Jumlah Salah</th>
          <th class="text-center">Nilai (Jumlah Benar x Nilai Penghitung)</th>
        </tr>
        <tr v-for="(item, i) in sampleNilai" :key="i">
          <td class="text-center" style="width: 3%">{{ i + 1 }}</td>
          <td class="text-center">{{ item.mapel }}</td>
          <td class="text-center">{{ item.benar }}</td>
          <td class="text-center">{{ item.salah }}</td>
          <td class="text-center">
            {{ parseInt(item.benar) * nilaiPenghitung }}
          </td>
        </tr>
      </table>
    </b-card>
  </section>
</template>
<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
  data: () => ({
    nilaiPenghitung: 2.5,
    sampleNilai: [
      { mapel: "Bahasa Indonesia", benar: 36, salah: 4 },
      { mapel: "Matematika", benar: 30, salah: 10 },
    ],
  }),
  methods: {
    goBack() {
      if (this.$route.query?.p) {
        const paketId = this.$route.query?.p;
        this.$router.push(
          `/master-admin/paket-tryout/detail/${paketId}?active_tab=rasionalisasi`
        );
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
