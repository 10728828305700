<template>
  <section>
    <component :is="currentPage"></component>
  </section>
</template>
<script>
import Bintara from "./Bintara.vue";
import Sma from "./Sma.vue";
import Utbk from "./Utbk.vue";
import Mandiri from "./Mandiri.vue";
import Kedinasan from "./Kedinasan.vue";
export default {
  components: {
    Bintara,
    Kedinasan,
    Sma,
    Utbk,
    Mandiri,
  },
  data: () => ({
    currentPage: null,
    allowedTypes: ["bintara", "kedinasan", "sma", "mandiri", "utbk"],
  }),
  created() {
    if (!this.allowedTypes.includes(this.$route.params.type)) {
      this.$router.push(`/error-404`);
    }
    this.currentPage = this.capitalize(this.$route.params.type);
  },
};
</script>
